@import "/var/www/wuestenrot/project/wuestenrot/web/src/theme/_variables.scss";@import "/var/www/wuestenrot/project/wuestenrot/web/src/dashboard/scss/dashboard.scss";
.list-page-structure {
  margin: 34px;
  background-color: white;
  border-radius: 8px;
  min-height: calc(100vh - 119px);
  padding: 32px;
}

.section-card {
  background-color: #fff;
  border-radius: 8px;
}

.right-dialog-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.dialog-container {
  min-width: 512px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dialog-container-thin {
  min-width: 512px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}

.dialog-container-without-buttons {
  min-width: 512px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  font-family: Inter, sans-serif;
}

.dialog-container-without-buttons.dialog-container-without-buttons-large {
  width: 540px;
}

.dialog-container-without-buttons-home {
  width: 512px;
}

.dialog-without-buttons-body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  padding: 0 24px 24px;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
}

@media (max-width: 900px) {
  .dialog-container {
    min-width: 0;
    height: 100%;
  }

  .dialog-container-thin {
    height: 100%;
    overflow-x: hidden;
    width: auto;
  }

  .contracts-header {
    flex-direction: column;
    gap: 20px;
  }

  .dialog-container-without-buttons {
    justify-content: flex-start;
    min-width: 0;
    height: 100%;
  }

  .dialog-container-without-buttons,
  .dialog-container-without-buttons-home {
    width: auto;
  }

  .list-page-structure {
    padding: 24px;
    margin: 16px;
  }
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
  padding: 0.55rem;
}

.ql-container {
  border: 1px solid #d1d5dd;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 120px;
}

.ql-tooltip {
  border-radius: 8px;
}

.ql-editor {
  min-height: 120px;
  font-size: 14px;

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}
